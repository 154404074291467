import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import { Canvas } from "@react-three/fiber";
import * as Loader from "react-loader-spinner";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const BackContainer = styled.div`
  display: block;
  background-image: url(/config/images/dn2.gif);
  position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 31vh;
  background-size: 500px;
  width: 100%;
  height: 100%;
  z-index: -100;
`;

export const StyledButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  :active {
    padding: 0.3rem 2rem;
  }
  :hover {
    color: purple;
    opacity: 0.8;
  }
`;

export const StyledConnectButton = styled.button`
  font-family: AEB;
  color: #000;
  min-width: 170px;
  min-height: 51px;
  font-size: 3rem;
  font-weight: 800;
  border-radius: 100px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  background-image: url("/config/images/connect.jpg");
  background-size: cover;
  /* animation: gradation 10.7s ease-in infinite; */
  :active {
    padding: 0.3rem 2rem;
    background-image: url("/config/images/connect.hover.jpg");
  }
  :hover {
    opacity: 0.9;
    /* background-image: url("/config/images/connect.hover.jpg"); */
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: inline-block;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-image: url("/config/images/main/exo1.png");
  background-size: cover;
  background-position: top;
  padding: 5rem;
  /* height: 100vh; */
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const StyledIncDecButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  padding: 10px;
  border-radius: 2px;
  margin: 0 2px;
  border: solid 1px #333;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100px;
  @media (min-width: 767px) {
    width: 500px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    opacity: 0.8;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click "Mint" to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWL, setmintAmountWL] = useState(1);
  const [mintAmountAL, setMintAmountAL] = useState(1);
  const [mintAmountPBMP, setMintAmountPBMP] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWL, setmintedWL] = useState(0);
  const [mintedAL, setmintedAL] = useState(0);
  const [whitelistProof, setWhitelistProof] = useState("");
  const [whitelistValid, setWhitelistValid] = useState(false);

  const [allowlistProof, setAllowlistProof] = useState("");
  const [allowlistValid, setAllowlistValid] = useState(false);

  const [publistProof, setPublistProof] = useState("");
  const [publistValid, setPublistValid] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const fetchDatas = (account) => {
  //   dispatch(fetchData(account));
  //   dispatch(fetchDataSub(account));
  // };

  const getWhitelist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let whitelist = require("./data/whitelist.json");

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setWhitelistProof(proof);
          setWhitelistValid(receipt);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
      // setWhitelistProof(proof);
      // setWhitelistValid(valid);
      console.log("WL:proof/" + proof);
      console.log("WL:root/" + root);
      // console.log(whitelistValid);
    }
  };

  const getAllowlist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let allowlist = require("./data/allowlist.json");

      let hashedAddresses = allowlist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isAllowlisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setAllowlistProof(proof);
          setAllowlistValid(receipt);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      console.log("AL:proof/" + proof);
      console.log("AL:root/" + root);
      // console.log(AllowlistValid);
    }
  };

  const getPublist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let publist = require("./data/publist.json");

      let hashedAddresses = publist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isPubliclisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setPublistProof(proof);
          setPublistValid(receipt);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
      // setWhitelistProof(proof);
      // setWhitelistValid(valid);
      console.log("PB:proof/" + proof);
      console.log("PB:root/" + root);
      // console.log(whitelistValid);
    }
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setPublicSaleMPEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleMPEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setWLSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const checkMintedWL = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWL(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const checkMintedAL = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .alMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedAL(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const claimNFTsWl = async () => {
    let cost = CONFIG.WEI_COSTWL;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountWL);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    let method = await blockchain.smartContract.methods.whitelistMint(
      mintAmountWL,
      whitelistProof
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.3);
    console.log("gasPrice: " + gasPrice); 

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(mintAmountWL, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedWL();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTsAl = async () => {
    let cost = CONFIG.WEI_COSTAL;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountAL);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    let method = await blockchain.smartContract.methods.allowlistMint(
      mintAmountAL,
      allowlistProof
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.3);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .allowlistMint(mintAmountAL, allowlistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedAL();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTsPbMP = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountPBMP);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .publicMintMP(mintAmountPBMP, publistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const decrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount - diffNum;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount + diffNum;
    if (newMintAmount > CONFIG.MAX_MINT) {
      newMintAmount = CONFIG.MAX_MINT;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountPBMP = (diffNum) => {
    let newMintAmountPBMP = mintAmountPBMP - diffNum;
    if (newMintAmountPBMP < 1) {
      newMintAmountPBMP = 1;
    }
    setMintAmountPBMP(newMintAmountPBMP);
  };

  const incrementMintAmountPBMP = (diffNum) => {
    let newMintAmountPBMP = mintAmountPBMP + diffNum;
    if (newMintAmountPBMP > CONFIG.MAX_MINT) {
      newMintAmountPBMP = CONFIG.MAX_MINT;
    }
    setMintAmountPBMP(newMintAmountPBMP);
  };

  const decrementmintAmountWL = (diffNum) => {
    let newmintAmountWL = mintAmountWL - diffNum;
    if (newmintAmountWL < 1) {
      newmintAmountWL = 1;
    }
    setmintAmountWL(newmintAmountWL);
  };

  const incrementmintAmountWL = (diffNum) => {
    let newmintAmountWL = mintAmountWL + diffNum;
    let maxNum = CONFIG.MAX_MINTWL - mintedWL;
    if (newmintAmountWL > maxNum) {
      newmintAmountWL = maxNum;
    }
    setmintAmountWL(newmintAmountWL);
  };

  const decrementMintAmountAL = (diffNum) => {
    let newMintAmountAL = mintAmountAL - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL(newMintAmountAL);
  };

  const incrementMintAmountAL = (diffNum) => {
    let newMintAmountAL = mintAmountAL + diffNum;
    let maxNum = CONFIG.MAX_MINTAL - mintedAL;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL(newMintAmountAL);
  };

  const getData = async () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchDataSub(blockchain.account));

      let pendings = await blockchain.web3.eth.getTransactionCount(
        CONFIG.CONTRACT_ADDRESS,
        "pending"
      );
      console.dir("pending:", pendings);
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getWhitelist();
    getAllowlist();
    getPublist();
    checkMinted();
    checkMintedWL();
    checkMintedAL();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };
  useEffect(() => {
    getConfig();
    getData();
    checkMinted();
    checkMintedWL();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      {blockchain.account === "" ||
      blockchain.smartContract === null ||
      blockchain.account === undefined ? (
        <></>
      ) : (
        <BackContainer />
      )}
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 0, backgroundColor: "rgba(0,0,0,0)" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/main/bg.png" : null}
        // image={isMobile ? "/config/images/pcbg.png" : "/config/images/pcbg.png"}
        // image={isMobile ? null : null}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 0 }}
          className={"kv"}
          test
        >
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <MainContainer
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{ width: "100vw", overflowX: "hidden" }}
              >
                <span className="features">
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    完売！！ありがとうございます！！今後ともよろしくお願いします。
                  </s.TextTitle>
                </span>
              </MainContainer>
            </>
          ) : (
            <>
              {blockchain.account === "" ||
              blockchain.smartContract === null ||
              blockchain.account === undefined ? (
                <MainContainer
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{ width: "100vw", overflowX: "hidden" }}
                >
                  <span className="features">
                    <s.Container
                      ai={"center"}
                      jc={"center"}
                      style={{ height: "100vh" }}
                    >
                      {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription> */}
                      <CircleTextLogo />
                      <s.SpacerSmall />

                      <StyledConnectButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      ></StyledConnectButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  </span>
                </MainContainer>
              ) : (
                <>
                  <s.Container flex={1} jc={"center"} ai={"center"}>
                    <CircleTextLogo />

                    <MainContainer
                      flex={2}
                      jc={"center"}
                      ai={"center"}
                      style={{
                        width: "100vw",
                        // overflowX: "hidden",
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        // backgroundImage: "url(/config/images/dn2.gif)",
                        // backgroundRepeat: "no-repeat",
                        // backgroundPosition: "center 31vh",
                        // backgroundAttachment: "fixed",
                        backgroundSize: "500px",
                        // borderRadius: 100,
                        padding: 100,
                        color: "white",
                        // animation: "anime 9s linear 0s infinite",
                        // WebkitAnimation: "anime 9s linear 0s infinite",
                      }}
                    >
                      <span className="features">
                        <s.Container ai={"center"}>
                          <s.TextTitle
                            style={{ textAlign: "center", color: "white" }}
                          >
                            A.E.B DN-2 PASS
                          </s.TextTitle>
                          {/* <s.TextTitle
                          style={{ textAlign: "center", color: "white" }}
                        >
                          WL Sale：2/4(土) 20:00〜
                        </s.TextTitle> */}

                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              fontSize: 50,
                              fontWeight: "bold",
                              color: "var(--accent-text)",
                            }}
                          >
                            {("00000" + data.totalSupply).slice(-5)}

                            {/* / {CONFIG.MAX_SUPPLY} */}
                          </s.TextTitle>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--primary-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.SCAN_LINK}
                            >
                              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerSmall />
                          {/* <s.TextTitle
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          1 {CONFIG.SYMBOL} costs
                        </s.TextTitle> */}
                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {"AL:"}
                            {CONFIG.DISPLAY_COSTAL}
                            {CONFIG.NETWORK.SYMBOL}
                            {/* {"(Max "}
                          {CONFIG.MAX_MINTWL}
                          {"mint)"} */}
                          </s.TextTitle>
                          {/* 
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"AL:"}
                          {CONFIG.DISPLAY_COSTAL}
                          {CONFIG.NETWORK.SYMBOL}
                          {"(Max "}
                          {CONFIG.MAX_MINTAL}
                          {"mint)"}
                        </s.TextTitle>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"Public:"}
                          {CONFIG.DISPLAY_COST}
                          {CONFIG.NETWORK.SYMBOL}
                          {"(Max ∞)"}
                        </s.TextTitle> */}
                          <s.SpacerXSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Excluding gas fees.（別途ガス代が必要です）
                          </s.TextDescription>

                          <s.SpacerSmall />
                          {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                      >
                      {"接続アドレス："+blockchain.account}
                      </s.TextDescription> */}
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          ></s.TextDescription>
                          {datasub.isPsEnabled ||
                          datasub.isPsMPEnabled ||
                          (whitelistValid && datasub.isWlEnabled) ||
                          (allowlistValid && datasub.isAlEnabled) ? (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {datasub.isWlEnabled ? "Free:" : ""}
                                {datasub.isAlEnabled ? "AL:" : ""}
                                {datasub.isPsMPEnabled || datasub.isPsEnabled
                                  ? "PB:"
                                  : ""}

                                {"Now On Sale!"}
                              </s.TextTitle>
                            </>
                          ) : (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"セール準備中..."}
                              </s.TextTitle>
                            </>
                          )}

                          {/* ギブアウェイ所有者の方ここから */}
                          {whitelistValid ? (
                            <>
                              {datasub.isPsMPEnabled || datasub.isPsEnabled ? (
                                <></>
                              ) : (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"あなたはフリーミント権所有者です("}
                                    {mintedWL}
                                    {"/"}
                                    {CONFIG.MAX_MINTWL}
                                    {")"}
                                  </s.TextDescription>
                                </>
                              )}

                              {datasub.isWlEnabled ? (
                                <>
                                  <s.SpacerMedium />
                                  {CONFIG.MAX_MINTWL <= mintedWL ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"Free MINTありがとうございました"}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsWl();
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "BUSY"
                                            : mintAmountWL + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* ギブアウェイ所有者の方ここまで */}
                          {allowlistValid ? (
                            <>
                              {datasub.isPsMPEnabled || datasub.isPsEnabled ? (
                                <></>
                              ) : (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"あなたはアローリスト所有者です("}
                                    {mintedAL}
                                    {"/"}
                                    {CONFIG.MAX_MINTAL}
                                    {")"}
                                  </s.TextDescription>
                                </>
                              )}
                              {datasub.isAlEnabled ? (
                                <>
                                  <s.SpacerMedium />
                                  {CONFIG.MAX_MINTAL <= mintedAL ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"AL MINTありがとうございました"}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      {/* <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementMintAmountAL(
                                            CONFIG.MAX_MINTAL
                                          );
                                          getData();
                                        }}
                                      >
                                        -{CONFIG.MAX_MINTAL}
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          decrementMintAmountAL(1);
                                          getData();
                                        }}
                                      >
                                        -1
                                      </StyledIncDecButton>
                                      <s.SpacerMedium />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                          padding: 20,
                                        }}
                                      >
                                        {mintAmountAL}
                                      </s.TextDescription>
                                      <s.SpacerMedium />
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementMintAmountAL(1);
                                          getData();
                                        }}
                                      >
                                        +1
                                      </StyledIncDecButton>
                                      <StyledIncDecButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          incrementMintAmountAL(
                                            CONFIG.MAX_MINTAL
                                          );
                                          getData();
                                        }}
                                      >
                                        +{CONFIG.MAX_MINTAL}
                                      </StyledIncDecButton>
                                    </s.Container>
                                    <s.SpacerSmall /> */}
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl();
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "BUSY"
                                            : mintAmountAL + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          <s.SpacerMedium />

                          {/* ここからパブリックセール */}
                          {/* 通常パブセ */}
                          {datasub.isPsEnabled ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"パブリックセール("}
                                {minted}
                                {"mint)"}
                              </s.TextDescription>

                              {CONFIG.MAX_MINT == minted ? (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"MINTいただきありがとうございました"}
                                  </s.TextDescription>
                                </>
                              ) : (
                                <>
                                  {/* <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledIncDecButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount(5);
                                      getData();
                                    }}
                                  >
                                    -5
                                  </StyledIncDecButton>
                                  <StyledIncDecButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount(1);
                                      getData();
                                    }}
                                  >
                                    -1
                                  </StyledIncDecButton>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                      padding: 20,
                                    }}
                                  >
                                    {mintAmount}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <StyledIncDecButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount(1);
                                      getData();
                                    }}
                                  >
                                    +1
                                  </StyledIncDecButton>
                                  <StyledIncDecButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount(5);
                                      getData();
                                    }}
                                  >
                                    +5
                                  </StyledIncDecButton>
                                </s.Container>
                                <s.SpacerSmall /> */}
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "BUSY"
                                        : mintAmount + " MINT"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"パブリックセールは開始していません"}
                            </s.TextDescription> */}
                            </>
                          )}
                          {/* 通常パブセここまで */}
                          {/* MPパブセ */}
                          {datasub.isPsMPEnabled ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"パブリックセール("}
                                {minted}
                                {"minted)"}
                              </s.TextDescription>

                              {CONFIG.MAX_MINT == minted ? (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"MINTいただきありがとうございました"}
                                  </s.TextDescription>
                                </>
                              ) : (
                                <>
                                  {/* <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledIncDecButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount(5);
                                      getData();
                                    }}
                                  >
                                    -5
                                  </StyledIncDecButton>
                                  <StyledIncDecButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount(1);
                                      getData();
                                    }}
                                  >
                                    -1
                                  </StyledIncDecButton>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                      padding: 20,
                                    }}
                                  >
                                    {mintAmount}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <StyledIncDecButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount(1);
                                      getData();
                                    }}
                                  >
                                    +1
                                  </StyledIncDecButton>
                                  <StyledIncDecButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount(5);
                                      getData();
                                    }}
                                  >
                                    +5
                                  </StyledIncDecButton>
                                </s.Container>
                                <s.SpacerSmall /> */}
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTsPbMP();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "BUSY"
                                        : mintAmount + " MINT"}
                                    </StyledButton>
                                  </s.Container>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {datasub.isPsEnabled || true ? (
                                <></>
                              ) : (
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"パブリックセールは開始していません"}
                                </s.TextDescription>
                              )}
                            </>
                          )}
                          {/* MPパブセここまで */}
                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            NFT Market Place
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.MARKETPLACE_LINK}
                            >
                              {CONFIG.MARKETPLACE}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerLarge />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButtonReload
                              onClick={(e) => {
                                e.preventDefault();
                                getReload();
                              }}
                            >
                              {claimingNft ? "Busy" : "RELOAD"}
                            </StyledButtonReload>
                          </s.Container>
                        </s.Container>

                        {/* オペレーションボタン */}
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ||
                        blockchain.account === undefined ? (
                          <></>
                        ) : (
                          <>
                            <OperationContainer
                              flex={6}
                              jc={"center"}
                              ai={"center"}
                            >
                              <s.Container ai={"center"} jc={"center"}>
                                {data.deployer == blockchain.account ||
                                "0x19640D52870A641D1cC52e0c609B2600CB340053" ==
                                  blockchain.account ? (
                                  <>
                                    {!data.isPublicSaleEnabled ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setPublicSaleEnable(true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "PB.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPublicSaleEnable(false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "PB.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}

                                    {!datasub.isWlEnabled ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setWLSaleEnable(true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "WL.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setWLSaleEnable(false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "WL.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}

                                    <StyledButtonOparator
                                      onClick={(e) => {
                                        e.preventDefault();
                                        withdraw();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "Busy" : "WITHDRAW"}
                                    </StyledButtonOparator>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </s.Container>
                            </OperationContainer>
                          </>
                        )}

                        {/* オペレーションボタン */}
                      </span>
                    </MainContainer>
                  </s.Container>
                </>
              )}
            </>
          )}
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
